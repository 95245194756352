@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";

.DocumentHeadline{
	@include mabinsoFont($colorLightBlack, 11px, 26px);
	margin-bottom:$sixTimes;
	.healines{
		float:left;
		padding-left: $twelveTimes;
		h1, h2{
    		font-weight:normal;
    		input{
                @include borderRadiusDefault();
                font-weight:normal;
                transition: min-width 0.2s, background 0.2s, border 0.2s;
                border:solid 1px transparent;
                background:transparent;
                min-width: 50px;
                margin-left:-1px;
                &:hover{
                    background: $noColor;
                    border-color: $borderColorStandard;
                }
                &:focus{
                    min-width: "calc(50vW - 50px)";
                    background: $colorCorporateLightTurquoise;
                    border: solid 1px $colorCorporateTurquoise;
                    outline: none;
                }
    		}
		}
		h1{
			@include mabinsoFont($colorLightBlack, 17px, 21px);
			margin-bottom: 2px;
			margin-top:5px;
			input{
    			@include mabinsoFont($colorLightBlack, 17px, 21px);
    			margin-top:-3px;
    			margin-bottom: -4px;
                height:26px;
			}
		}
		h2{
			@include mabinsoFont($colorLightBlack, 13px, 16px);
			input{
    			@include mabinsoFont($colorLightBlack, 13px, 16px);
			}
		}
	}
	.buttonHolder{
		float:right;
		text-align:right;
	}
}
