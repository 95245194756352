@import "../../../../css/_colors";
@import "../../../../css/_mixins";
@import "../../../../css/_sizes";
@import "../../../../css/_superstandards";
@import "../../../../css/_body";
@import "../../../../css/_simple-grid";

@mixin checkedState($svg){
	text-align:right;
    display:inline-block;
    background:url($svg);
    background-position: center center;
    background-repeat: no-repeat;
    float:left;
    text-indent: 1000px;
    width: 20px;
    overflow:hidden;
    margin:0px 0 0 5px;
}

.TableSelector{
    line-height: 22px;
    @extend .mabinsoRoundCorner;
    background: $colorLightGray;
    border:solid 1px $borderColorStandard;
    padding: 1px;
    user-select: none;
    .selected {
        background: $colorCorporateLightTurquoise;
    }
    .unselected {
        background: $noColor;
    }
	.-even{
    	background:$noColor;
	    &:hover{
    	    .unchecked {
                @include checkedState('../img/row_hover.svg');
            }
	    }
	}
	.-odd{
    	@include gradient($noColor, #eee);
	    &:hover{
    	    .unchecked {
                @include checkedState('../img/row_hover.svg');
            }
	    }
	}
	.td {
    	padding: 1px 5px!important;
	}
	.checked {
    	@include checkedState('../img/row_selected.svg');
	}
	.unchecked {
    	@include checkedState('../img/row_unselected.svg');
	}
	.elementsSelected {
    	font-size: 11px;
    	padding: 2px 5px 0 0;
	}
}
