@import "../../../css/_colors";
@import "../../../css/_mixins";
@import "../../../css/_sizes";
@import "../../../css/_superstandards";
@import "../../../css/_body";

.Filter{
	&:hover{
		.ButtonFilter{
			background-color: $colorMidLightGray;
			color:$colorLightBlack;
		}
	}
	.right{
		.ButtonFilter{
			margin-right:$oneTimes;
			margin-left:0px;
		}
	}
}
.ButtonFilter{
	@extend .mabinsoNoTextSelect;
	@extend .mabinsoRoundCorner;
	background-color: $colorMidGray;
	color: $colorDarkGray;
	cursor: pointer;
	display: inline-block;
// 	float: left;
	font-size: 11px;
	font-family: $font-family;
	height: $fourTimes;
	line-height: $fourTimes;
	margin: 5px 0 0 5px;
	padding: 0 10px;
	&:hover{
		background-color: $colorMidLightGray;
		color:#000;
		text-decoration:underline;
	}
	&:active{
		background-color: $colorMidGray;
	}
}
