@import "../../../css/_mabinso";
@import "variables.scss";
@import "mixins.scss";

:global(.react-datepicker__input-container){
	input[type="text"]{
		@extend .mabinsoRoundCorner;
		@include mabinsoFont($fontColorDefault, 11px, $threeTimes);
		border:solid 1px $borderColorStandard;
		height:$fiveTimes;
		padding: 0 $oneTimes;
		width:calc(100% - 30px);
		box-sizing: border-box;
		&:focus{
			background-color:$colorCorporateLightTurquoise;
			border-color:$colorCorporateTurquoise;
		}
	}
	input{
		&:focus{
			outline: none;
		}
	}
}
:global(.datePickerWrapper){
	display:inline-block;
	& >div{
		display:inline-block;
		vertical-align: top;
	}
	input[type="text"]{
		width:calc(100% - 5px);
	}
	label{
		text-overflow: ellipsis;
		margin: 0px $oneTimes 0px 0px;
		display:inline-block;
		width: 30px;
		overflow: hidden;
	}
	:global(.react-datepicker__day--disabled){
		opacity:0.4;
	}
	:global(.timePlaceholder){
		width:$threeTimes;
	}
}
