@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_simple-grid";

.label {
    label{
        @include mabinsoFont($fontColorDefault, 10px, $threeTimes);
        display:block;
        margin:0 0 0 $twoTimes;
        padding:$oneTimes 0;
    }
}