/* Hilfe */
.help{
	position:fixed;
	bottom:0px;
	//top:-9000px;
	left:50%;
	width:852px;
	margin-left:-489px;
	border-top:solid 1px #003c32;
	border-left:solid 1px #003c32;
	border-right:solid 1px #003c32;
	background:rgb(0, 91, 83);
	/* background:rgba(0, 91, 83, 0.9); */
	text-align:left;
	padding:20px 20px 20px 106px;
	border-radius: 3px;
	-moz-border-radius: 3px;
	-webkit-border-radius: 3px;
	box-shadow: 0 0 2px #003c32;
	min-height:123px;
	font-size:12px;
	line-height:20px;
	color:#fff;
	z-index: 10000;
	ul{
		margin-left:14px;
	}
	a{
		color: #fff;
		text-decoration: underline!important;
	}
}
.icon{
	width:65px;
	height:66px;
	position:absolute;
	left:20px;
	top:18px;
	text-indent: -1000px;
	overflow: hidden;
	background-position:-404px -349px;
	background-image: url(../../images/sprites_main.png);
	background-repeat: no-repeat; 
}
