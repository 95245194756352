$oneTimes:5px;
$twoTimes:10px;
$threeTimes:15px;
$fourTimes:20px;
$fiveTimes:25px;
$sixTimes:30px;
$sevenTimes:35px;
$eightTimes:40px;
$nineTimes:45px;
$tenTimes:50px;
$elevenTimes:55px;
$twelveTimes:60px;;