$datepicker__navigation-disabled-color:#fff;

$datepicker__background-color: $colorCorporateLightBrown !default;
$datepicker__border-color: $colorCorporateBrown !default;
$datepicker__highlighted-color: #3dcc4a !default;
$datepicker__muted-color: $colorCorporateBrown !default;
$datepicker__selected-color: $colorCorporateBrown !default;
$datepicker__hover-color: $colorCorporateLightTurquoise !default;
$datepicker__text-color: $fontColorDefault !default;
$datepicker__text-color_header: $noColor !default;
$datepicker__header-color: $noColor;

$datepicker__border-radius: 3px !default;
$datepicker__day-margin: 2px !default;
$datepicker__font-size: $twoTimes !default;
$datepicker__item-size: $fourTimes !default;
$datepicker__margin: $oneTimes !default;
$datepicker__navigation-size: 5px !default;
$datepicker__triangle-size: 6px !default;
$datepicker__font-family: $font-family;
