@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";

.TableOfContentsChapter{
	@extend .mabinsoNoTextSelect;
	margin: 0 0 $oneTimes 0;
	overflow:hidden;
	position: relative;
	z-index:1;
	text-align:left;
	width:100%;
	.svgBackground{
		bottom:0px;
		height:2000px;
		position:absolute;
		right:-1px;
		width: 2000px;
		z-index:1;
		polygon{
			fill:transparent;
			stroke:$colorMidGray;
			stroke-width:1px;
		}
	}
	.inner{
		border-top: solid 1px transparent;
		border-left: solid 1px transparent;
		padding:$twoTimes;
		position: relative;
		z-index:3;
		h1{
			@include mabinsoFont($colorLightBlack, 13px, 16px);
			font-weight:normal;
			margin-bottom:$oneTimes;
		}
		h2{
			@include mabinsoFont($colorDarkGray, 12px, 15px);
			font-weight:normal;
		}
	}
	.actions{
		display:none;
		position:absolute;
		right:$oneTimes;
		top:calc(50% - 10px);
		z-index:4;
	}
	&.active, &:hover{
		.svgBackground{
			position:absolute;
			right:0px;
			polygon{
				fill:$colorCorporateLightTurquoise;
				stroke:$colorCorporateTurquoise;
				stroke-width:1px;
			}
		}
		.inner{
			border-top: solid 1px $colorCorporateTurquoise;
			border-left: solid 1px $colorCorporateTurquoise;
			h2{
				@include mabinsoFont($colorCorporateTurquoise, 12px, 15px);
			}

		}
		.actions{
			display:none;
		}
	}
}
