@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";
@import "../../css/_simple-grid";


.forminput{
	input[type="text"]{
		@extend .mabinsoRoundCorner;
		@include mabinsoFont($fontColorDefault, 11px, $threeTimes);
		border:solid 1px $borderColorStandard;
		height:$fiveTimes;
		margin:0 $fourTimes $oneTimes 0;
		padding: 0 $oneTimes;
		width:calc(100% - 30px);
		box-sizing: border-box;
		&:focus{
			background-color:$colorCorporateLightTurquoise;
			border-color:$colorCorporateTurquoise;
		}
	}
	input{
		&:focus{
			outline: none;
		}
	}
}
@media only screen and (max-width: 720px) {
	.forminput{
		input[type="text"]{
			margin-left:10px;
		}
	}
}
