@import "../../css/_colors";
@import "../../css/_mixins";
@import "../../css/_sizes";
@import "../../css/_superstandards";
@import "../../css/_body";
@import "../../css/_simple-grid";


.formselect{
	:global(.Select){
		margin:0 $oneTimes $oneTimes 0;
		width: 100%;
		box-sizing: border-box;
		min-height:$fiveTimes;
		outline: none;
		&:global(.Select--multi){
			:global(.Select-value-icon, .Select-value-label){
				background-color:$colorCorporateLightBrown;
			}
			:global(.Select-value){
				border-radius:3px;
				border:none;
				margin:1px 0px 1px 1px;
				height:21px;
				line-height:21px;
				&:hover{
					:global(.Select-value-icon, .Select-value-label){
						color:$colorCorporateTurquoise;
						background-color:$colorCorporateLightTurquoise;
					}
					:global(.Select-value-icon){
						border-right:solid 1px $colorCorporateTurquoise;
						color:$colorCorporateTurquoise;
					}
				}
				:global(.Select-value-label){
					padding: 0 $oneTimes 0 3px;
					line-height:21px;
					margin-top:-2px;
					color:$noColor;
				}
				:global(.Select-value-icon){
					padding: 0 $oneTimes 0;
					line-height:21px;
					margin-top:-2px;
					border-right:solid 1px $colorCorporateBrown;
				}
			}
			:global(.Select-placeholder){
				background:transparent;
			}
		}
		&:global(.time){
			width:75px;
			display:inline-block;
		}
		&:global(.is-open){
			:global(.Select-control){
				border:solid 1px $colorCorporateBrown;
			}
			:global(.Select-value){
				background:$colorCorporateLightBrown;
			}
			:global(.Select-arrow-zone){
				:global(.Select-arrow){
					margin-top:9px;
					border-color: transparent transparent $darkbrown transparent;
				}
			}
		}
		&:active, &:focus{	// ÄNDERUNGBENÖTIGT?
			outline: none;
		}
		:global(.Select-control){
			@extend .mabinsoRoundCorner;
			outline: none;
			border:solid 1px $darkbrown;
			height:$fiveTimes;
			line-height:$threeTimes;
			box-sizing: border-box;
			&:active, &:focus{	// 	// ÄNDERUNGBENÖTIGT?
 				border:solid 1px $colorCorporateBrown;
 				outline: none;
 				box-shadow: none;
			}
			:global(.Select-input){
				height:15px;
				padding-left:$oneTimes;
				padding-right:$oneTimes;
				:global(input){
					height:23px;
					margin:0px;
					padding:0px;
				}
			}
		}
		:global(.Select-arrow-zone){
			height:$fourTimes;
			padding:0px;
			border-left:solid 1px $darkbrown;
			margin:0px;
			:global(.clock){
				background-image:url('./clock.svg');
				background-size: 15px 15px;
				display:block;
				width:24px;
				height:23px;
				background-repeat: no-repeat;
				background-position:4px 5px;
			}
			:global(.Select-arrow){
				margin-top:9px;
				border-color: $darkbrown transparent transparent transparent;
			}
		}
		:global(.Select-menu-outer){
			border-color:$colorCorporateBrown;
			border-top-color:$colorCorporateLightTurquoise;
			:global(.Select-option) {
				padding: $oneTimes 10px;
			}
			:global(.Select-option.is-selected) {
			  background-color: $colorCorporateLightTurquoise;
			  color:#000;
			}
			:global(.Select-option.is-focused) {
			  background-color: $colorCorporateLightTurquoise;
			}
		}
		:global(.Select-clear-zone){
			height:23px;
			color: $darkbrown;
			border-left:solid 1px $darkbrown;
			width:25px;
			:global(.Select-clear){
				font-size:$threeTimes;
			}
		}
		:global(.Select-value),
		:global(.Select--single > .Select-control .Select-value),
		:global(.Select-placeholder){
			background:$colorCorporateLightBrown;
			top:0px;
			line-height:$fiveTimes!important;
			height:25px!important;
			color:$noColor!important;
			:global(.Select-value-label){
				color:$noColor!important;
			}
		}
		:global(.Select-value-label){
			line-height:$fiveTimes;
		}
	}
	:global(.has-value.Select--single > .Select-control .Select-value .Select-value-label),
	:global(.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label){
		color:$noColor;
	}
}
@media only screen and (max-width: 720px) {
	.formselect{
		.Select{
			margin:0 $oneTimes 0 $oneTimes;
			width: 100%;
		}
	}
}
