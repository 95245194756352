@import "../../../../css/_colors";
@import "../../../../css/_mixins";
@import "../../../../css/_sizes";
@import "../../../../css/_superstandards";
@import "../../../../css/_body";

.Filter{
	&:hover{
		.Button{
			background-color: $colorMidLightGray;
			color:$colorLightBlack;
		}
	}
	.right{
		.Button{
			margin-right:$oneTimes;
			margin-left:0px;
		}
	}
}
.Button{
	@extend .mabinsoNoTextSelect;
	@extend .mabinsoRoundCorner;
	background-color: $colorCorporateLightBrown;
	border: solid 1px $colorCorporateBrown;
	color: $noColor;
	cursor: pointer;
	display: inline-block;
// 	float: left;
	font-size: 11px;
	font-family: $font-family;
	height: 13px;
	line-height: 13px;
	margin: 5px 0 0 5px;
	padding: 0 5px;
	&:hover{
		background-color: $colorCorporateBrown;
		border: solid 1px $colorCorporateBrown;
		color:$noColor;
		text-decoration:none;
	}
	&:active{
		background-color: $colorMidGray;
	}
}
