@import "../../../css/_mabinso";
// @import "variables.scss";
// @import "mixins.scss";

:global(.timePickerWrapper.endTime){
  label{
    margin-left: 10px!important;
  }
}
:global(.timePickerWrapper){
  display:inline-block;
  & >div{
    display:inline-block;
    vertical-align: top;
  }
  input[type="text"]{
    width:calc(100% - 5px);
  }
  label{
    text-overflow: ellipsis;
    margin: 0px $oneTimes 0px 0px;
    display:inline-block;
    width: 30px;
    overflow: hidden;
  }
  .react-datepicker__day--disabled{
    opacity:0.4;
  }
  :global(.Select-value-label){
    font-size:11px;
  }
  :global(.Select-multi-value-wrapper){
    height:23px;
  }
  :global(.Select-control){
    height:25px;
  }
  :global(.Select-value) {
    padding: 0 5px!important;
    line-height:  24px!important;
  }
  :global(.Select-value-label) {
    line-height:  24px;
  }
}

@media only screen and (max-width: 720px) {
  :global(.timePickerWrapper){
    .Select{
      margin-bottom:$oneTimes;
    }
    label{
      width:100%;
      margin: 0px $oneTimes 0px $twoTimes;
    }
  }
}
